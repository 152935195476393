.hero {
  width: 100%;
  margin: 0 auto 0;
  background-color: black;
  padding: 1.6rem;
  padding-bottom: 5rem;
  position: relative;
  img {
    width: 100%;
    display: block;
    margin: 0 auto;
    margin-top: 10px;
    border-radius: 50%;
  }
  h1 {
    position: relative;
    top: -5rem;
    font-size: 4.8rem;
    color: white;
    span {
      color: #fba70b;
    }
  }
  p {
    color: #7a7a7a;
    font-size: 1.6rem;
    font-weight: bold;
  }
}

@media screen and (min-width: 600px) {
  .hero {
    img {
      width: 65%;
    }
  }
}

@media screen and (min-width: 800px) {
  .hero {
    img {
      width: 35%;
    }
    width: 95%;
    margin: 2rem auto;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    div.right {
      h1 {
        top: 0;
        margin-bottom: 1rem;
      }
    }
  }
}

@media screen and (min-width: 1250px) {
  .hero {
    img {
      width: 30%;
      display: inline-block;
      margin-left: auto;
    }
    div.right {
      width: 40%;
      margin-right: auto;
      h1 {
        top: 0;
        margin-bottom: 3rem;
      }
      p {
        max-width: 710px;
      }
    }
  }
}
