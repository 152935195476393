.blog-container {
  & > img {
    max-width: 100%;
    max-height: 210px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  img.error {
    max-width: 400px;
  }
  small {
    margin: unset !important;
    gap: 20px!important;
    justify-content: unset!important;
  }
  div.error {
    width: 100%;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h3 {
      font-size: 2.4rem;
      color: #fba70b;
      margin-bottom: 1rem;
    }
    button {
      color: #fba70b;
      background-color: #010101;
      border: none;
      padding: 1rem;

      border-radius: 5px;
      font-size: 1.8rem;
    }
  }
  div.content {
    padding: 1.6rem;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;

    h1 {
      color: #fba70b;
      font-size: 2.4rem;
      margin-bottom: 0.5rem;
    }
    b {
      color: #010101;
    }
    div {
      display: flex;
      gap: 30px;
      margin: 1rem 0rem;
      margin-bottom: 20px;
      justify-content: space-between;
      div {
        flex-direction: column;
        gap: unset;
        margin: unset;
      }
      button {
        padding: 15px 20px;
        border-radius: 10px;
        border: none;
        background-color: #fba70b;
        color: #fff;
        cursor: pointer;
        font-size: normal;
      }
    }
    em, small {
      font-style: normal;
      color: #999;
      padding-right: 10px;
    }
    // em {
    //   display: flex;
    //   gap: 30px;
    // }
    p {
      margin-top: 4rem;
      font-size: 1.6rem;
      span {
        display: block;
        padding: 2rem;
        text-align: center;
        color: #fba70b;
        background: linear-gradient(#fff, #fba70b);
        height: 150px;
        margin-top: -4rem;
        padding-top: 4rem;    
        font-weight: 550;
        font-size: 25px;
        a{cursor: pointer;}
      }
    }
  }

  div.loader-container {
    height: 200px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (min-width: 1200px) {
  .blog-container {
    & > img {
      max-height: 380px;
      border-radius: 1rem;
    }
    div.error {
      h3 {
        font-size: 4rem;
      }
      button {
        font-size: 2.4rem;
        width: 200px;
      }
    }
    div.content {
      margin-bottom: 5rem;
      h1 {
        font-size: 3.6rem;
      }
      em, small, button {
        font-size: 1.4rem;
      }
      p {
        font-size: 2rem;
      }
      em, small {
        p {
          font-size: 1.5rem;
        }
      }
    }
  }
}
