.newsList, .investor-relation-files {
  margin-top: 3rem;
  padding: 1.6rem;
  div.loader-container {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  div.error {
    h3 {
      &::after {
        display: none;
      }
    }
  }
  h3 {
    font-size: 2.6rem;
    position: relative;
    margin-bottom: 4rem;
    &::after {
      content: "";
      position: absolute;
      height: 2px;
      width: 25%;
      background-color: #fba70b;
      bottom: -10px;
      left: 0;
    }
  }
  .pagination {
    margin-top: 3rem;
    display: inline-block;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    button {
      margin: 0 0.2rem;
      width: 30px;
      height: 30px;
      padding: 0.5rem;
      background-color: black;
      color: #fba70b;
      border: none;
      border-radius: 5px;
      &:disabled {
        cursor: not-allowed;
      }
      &.active {
        background-color: #fba70b;
        color: white;
      }
    }

    .left {
      margin-right: 0.5rem;
    }
    .right {
      margin-left: 0.5rem;
    }
  }
}

.investor-relation-files {
  img {
    max-height: 220px;
  }
}

@media screen and (min-width: 600px) {
  .newsList, .investor-relation-files {
    h3::after {
      width: 15%;
    }
  }
}

@media screen and (min-width: 800px) {
  .newsList, .investor-relation-files {
    div.container {
      display: grid;
      row-gap: 4rem;
      column-gap: 2rem;
      grid-template-columns: auto auto;
    }
    margin-bottom: 4rem;
  }
}

@media screen and (min-width: 1200px) {
  .newsList, .investor-relation-files {
    padding-left: 5.4rem;
    padding-right: 5.4rem;
    div.container {
      grid-template-columns: auto auto auto;
    }
  }

  .investor-relation-files {
    div.container {
      grid-template-columns: repeat(2, 1fr);
      padding: 0rem 2rem;
    }
  }
}