div.desktop-nav {
  display: none;
  p {
    margin: unset;
    font-weight: 600 !important;
    cursor: pointer;
    text-transform: capitalize !important;
    color: #fba70b !important;
  }
}

div.mobile-nav {
  position: sticky;
  top: 0;
  z-index: 1;
  header {
    background-color: #f7f7f7;
    position: relative;
    padding: 0 1rem;
    img {
      &.logo {
        width: 60%;
        display: block;
      }
    }
    button {
      position: absolute;
      right: 30px;
      top: 50%;
      transform: translateY(-50%);
      background-color: transparent;
      width: 50px;
      height: 50px;
      border: none;
      transition: all 0.5s ease-in-out;
      span {
        position: relative;
        display: block;
        height: 1px;
        width: 100%;
        background-color: #1e1e1e;
        transition: all 0.5s ease-in-out;
        border-radius: 5px;
        &.first,
        &.third {
          width: 75%;
        }
        &.first {
          transform: translateY(-10px);
          &.open {
            transform: rotate(45deg) translateY(1px);
          }
        }
        &.second {
          &.open {
            transform: translateX(-35px);
            background-color: transparent;
          }
        }
        &.third {
          transform: translateY(10px);
          &.open {
            transform: rotate(-45deg) translateY(-1px);
          }
        }
      }
      /* ANIMATION */
    }
  }

  nav {
    top: 0;
    width: 100%;
    background: #f7f7f7;
    color: #191a20;
    z-index: 1;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
    &.open {
      max-height: 300px;
    }
    ul {
      li {
        text-align: center;
        border-top: 1px solid #eee;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        &.submenu {
          max-height: 0;
          overflow: hidden;
          transition: max-height 0.5s ease-in-out;
          a, p {
            font-size: 12px;
            font-weight: 400;
          }
          &.open {
            max-height: 50px;
            flex-direction: column;
            gap: 10px;
          }
        }

        a {
          letter-spacing: 1px;
          font-size: 1.6rem;
          font-weight: 500;
          display: inline-block;
          text-decoration: none;
          color: #191a20;
        }
        svg {
          margin-left: 1rem;
          font-size: 1.2rem;
        }
        &.investor {
          transition: all 0.5s ease-in-out;
          position: relative;
          div.subMenu {
            overflow: hidden;
            max-height: 0;
            position: absolute;
            background-color: white;
            width: 80%;
            flex-direction: column;
            z-index: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all 0.5s ease-in-out;
            left: 30px;
          }
          &:hover {
            div.subMenu {
              max-height: 160px;
              padding: 0.5rem;
              flex-direction: column;
            }
          }
        }
      }
    }
  }
  p {
    margin: unset;
    font-weight: 600 !important;
    cursor: pointer;
    text-transform: capitalize !important;
    color: #fba70b !important;
  }
}

.form-group {
  svg {display: none;}
  .stsearch-box {
    padding: unset;
  }
}
.form-group:focus, input:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25);
}

div.desktop-nav {
  border-bottom: solid 1px #ccc;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 4rem;
  div.left {
    img {
      width: 200px;
    }
  }
  div.right {
    width: 75%;
    nav {
      ul {
        display: flex;
        justify-content: space-between;
        align-items: center;
        list-style-type: none;
        overflow: visible;
        li {
          a, p {
            font-weight: 500;
            color: #7a7a7a;
            text-transform: uppercase;
            height: 40px;
            line-height: 40px;
            border: transparent;
            letter-spacing: 1px;
            font-size: 1.6rem;
            text-decoration: none;
            &:hover {
              color: #fba70b;
            }
          }
          a.active {
            border-bottom: 1px solid #fba70b;
          }
          &.investor {
            transition: all 0.5s ease-in-out;
            position: relative;
            div.subMenu {
              overflow: hidden;
              max-height: 0;
              position: absolute;
              background-color: white;
              width: 80%;
              flex-direction: column;
              z-index: 1;
              display: flex;
              justify-content: center;
              align-items: center;
              transition: all 0.5s ease-in-out;
              left: 30px;
            }
            &:hover {
              div.subMenu {
                max-height: 160px;
                padding: 0.5rem;
                flex-direction: column;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 600px) {
  div.mobile-nav {
    header {
      img.logo {
        width: 35%;
      }
    }
  }
}

@media screen and (min-width: 800px) {
  div.mobile-nav {
    display: none;
  }
  div.desktop-nav {
    display: flex;
  }
}

@media screen and (min-width: 1200px) {
  div.desktop-nav {
    display: flex;
    padding-right: 5.4rem;
    div.right {
      width: 50%;
    }
  }
}

@media screen and (min-width: 1450px) {
  div.desktop-nav {
    padding-left: 10rem;
    padding-right: 10rem;
    div.right {
      width: 50%;
    }
  }
}
