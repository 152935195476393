footer {
  background-color: black;
  color: white;
  padding: 1.6rem;

  section.finance-info-clone,
  section.contact-clone {
    display: none;
  }

  section {
    &.finance-info,
    &.finance-info-clone {
      h4 {
        text-transform: uppercase;
      }
      a {
        font-weight: bold;
        color: #007bff;
        font-style: normal;
        font-size: 1.5rem;
        display: block;
        text-decoration: none;
      }
    }
    &.footnote {
      text-align: center;
      border-top: solid 1px #ccc;
      margin: 0;
      em {
        font-style: normal;
        font-size: 1.2rem;
        display: block;
        padding-top: 1rem;
      }
    }
    margin-bottom: 2rem;
    h4 {
      font-size: 2rem;
      margin-bottom: 1rem;
    }

    p {
      font-size: 1.5rem;
    }
    ul {
      list-style-type: none;
      li {
        margin: 1rem 0;
        button {
          background-color: transparent;
          border: none;
          font-weight: bold;
          color: #007bff;
          svg {
            display: inline-block;
            margin-right: 0.5rem;
          }
        }
        a {
          color: white;
          font-size: 1.5rem;
          text-decoration: none;

          &.contact-link {
            font-weight: bold;
            color: #007bff;
            svg {
              display: inline-block;
              margin-right: 0.5rem;
            }
          }
          &.finance-link {
            display: block;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 600px) {
  div.group {
    display: grid;
    section.about {
      grid-area: about;
    }
    section.services {
      grid-area: services;
    }
    section.quickLink {
      margin-right: 12rem;
      grid-area: quickLink;
    }
    grid-template-areas:
      "about about about"
      "quickLink services .";
  }
  section.about {
    max-width: 400px;
  }
}

@media screen and (min-width: 800px) {
  footer {
    section.contact,
    section.finance-info {
      display: none;
    }
    section.finance-info-clone,
    section.contact-clone {
      display: block;
    }

    section.finance-info-clone {
      grid-area: financeInfo;
    }

    section.contact-clone {
      grid-area: contact;
    }
  }
  div.group {
    grid-template-areas:
      "about quickLink services"
      "contact quickLink financeInfo";
  }
  section.about,
  section.finance-info-clone,
  section.contact-clone {
    max-width: 300px;
  }
}

@media screen and (min-width: 1200px) {
  footer {
    padding-left: 5.4rem;
    padding-right: 5.4rem;
  }
}
