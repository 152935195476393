.newsCard {
  cursor: pointer;
  width: 100%;
  border: solid 1px #fba70b;
  border-radius: 1rem;
  margin-bottom: 4rem;
  box-shadow: 2.2px 4.4px 10px rgb(0 0 0 / 20%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  img {
    max-width: 220px;
    max-height: 150px;
    margin: auto auto;
    display: block;
  }
  div.info {
    background-color: #f3f0eb;
    padding: 0.8rem;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    h4 {
      font-size: 1.6rem;
      margin-bottom: 0.5rem;
    }
    em {
      font-style: normal;
      color: rgb(100, 99, 99);
      font-size: 1rem;
    }
  }
}

@media screen and (min-width: 600px) {
  .newsCard {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: 800px) {
  .newsCard {
    margin: 0;
  }
}
